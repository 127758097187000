.card {
  background: #fff;
  border: 4px solid transparent;
  position: relative;
  padding: 32px 0 32px 32px;
  text-align: left;
  margin-bottom: 40px;
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  border-radius: 32px;
  cursor: pointer;
  box-shadow: 0px 2px 12px 0px #e3e6f3;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;

  &:hover {
    border: 4px solid var(--app-primary-color);

  }

  // &:last-child {
  //   margin-bottom: 0;
  // }

  >.title {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    line-height: 34px;
    margin-bottom: 12px;
    width: 70%;
    word-wrap: break-word;
    /* 添加 */
    word-break: break-word;
    /* 添加 */
    white-space: normal;
    /* 添加 */
  }

  >.desc {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #909093;
    line-height: 22px;
    width: 55%;
    margin: 0;
    word-wrap: break-word;
    /* 添加 */
    word-break: break-word;
    /* 添加 */
    white-space: normal;
    /* 添加 */
  }
}

.content {
  margin-left: -1rem;
  margin-right: -1rem;

  color: var(--app-text-color);
  //
  margin-bottom: 184px;

  h2 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #292d32;
    // line-height: 50px;
    margin-bottom: 24px;
    padding: 0 1rem;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .text {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 1rem;
    margin-bottom: 4rem;

    p {
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      color: #909093;
      line-height: 34px;

      &:nth-child(2) {
        padding: 0 200px;
      }
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .topImageContainer {
    margin-bottom: 2rem;
    display: none;
    min-height: 350px;

    .topImage {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  >.bottomContainerPC {
    display: grid;
    grid-template-columns: 1fr 1fr;

    >.leftSubContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }
    }
  }

  .bottomContainerMobile {
    width: 100%;
    // overflow-x: auto;
    // overflow-y: hidden;
    // display: flex;
    // justify-content: center;
    // white-space: nowrap;

    // &::-webkit-scrollbar {
    //   width: 0;
    //   height: 0;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background-color: transparent;
    // }

    // &::-webkit-scrollbar-track {
    //   background-color: transparent;
    // }

    // > .leftSubContainer {
    //   display: inline-block;
    //   width: calc(50% - 10px);
    // }

    display: none;
  }
}

@media screen and (min-width: 900px) {
  .content {
    margin-bottom: 184px;

    >.bottomContainer {
      grid-template-columns: 1fr 1fr;

      >.leftSubContainer {
        &:first-child {
          margin-right: 20px;
        }

        &:last-child {
          margin-left: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .card {
    background-color: #fff;
    width: calc(100% - 32px);
    margin: 16px;
    margin-bottom: 32px;
    padding: 16px 0 16px 16px;

    // &:last-child {
    //   margin-right: 0;
    // }

    display: inline-flex;
    flex-direction: column;
    // justify-content: space-between; 
    height: 100%;
    min-height: 400px;
    // box-sizing: border-box; 
  }

  .content {

    .bottomContainerPC {
      display: none;
    }

    .bottomContainerMobile {
      padding: 1rem;
      display: block;
    }
  }
}

@media (max-width: 900px) and (min-width: 768px) {

  // .card { 
  //   width: 400px;
  // }

  .content {
    margin-bottom: 154px;

    .text {
      p {
        &:nth-child(2) {
          padding: 0;
        }
      }
    }

    // > .bottomContainer {
    //   grid-template-columns: 1fr 1fr;

    //   > .leftSubContainer {
    //     &:first-child {
    //       margin-right: 20px;
    //     }
    //     &:last-child {
    //       margin-left: 20px;
    //     }
    //   }
    // }
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  // .card { 
  //   width: 350px;
  // }

  .content {
    margin-bottom: 119px;

    .text {
      p {
        &:nth-child(2) {
          padding: 0;
        }
      }
    }

    // > .bottomContainer {
    //   grid-template-columns: 1fr;

    //   > .leftSubContainer {
    //     &:first-child {
    //       margin-right: 0;
    //     }
    //     &:last-child {
    //       margin-top: 40px;
    //       margin-left: 0;
    //     }
    //   }
    // }
  }
}

@media screen and (max-width: 576px) {
  .card {
    // width: 300px;
    min-height: 400px;
  }

  .content {
    margin-bottom: 50px;

    h2 {
      font-size: 32px;
      margin-bottom: 24px;
    }

    .text {
      p {
        font-size: 18px;

        // margin-bottom: 34px;
        &:nth-child(2) {
          padding: 0;
        }
      }
    }

    // > .bottomContainer {
    //   grid-template-columns: 1fr;

    //   > .leftSubContainer {
    //     &:first-child {
    //       margin-right: 0;
    //     }
    //     &:last-child {
    //       margin-top: 40px;
    //       margin-left: 0;
    //     }
    //   }
    // }
  }
}
